import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { checkLogin } from '@lib/api';
import { DangerToast } from '@components/GlobalToastList';
import { Eye, EyeSlash } from '@icons';

const AdminAccess = () => {
  const [email, setEmail] = useState(process.env.REACT_APP_DEMO_USER ? process.env.REACT_APP_DEMO_USER : '');
  const [password, setPassword] = useState(process.env.REACT_APP_DEMO_PASSWORD ? process.env.REACT_APP_DEMO_PASSWORD : '');
  const [showPass, setShowPass] = useState(false);
  const [areCredentialsCorrect, setAreCredentialsCorrect] = useState(true);
  const loginType = 2;
  // console.log('email', email);
  // console.log('password', password);

  const handleLogin = async (event) => {
    event.preventDefault();

    if (email === '' || password === '') {
     DangerToast(
       'Attenzione',
       'I campi non possono essere vuoti.'
     );
     
     return;
   } 

    await checkLogin(email, password, loginType).then((status) => {      
      if (status === 200) {
        setAreCredentialsCorrect(true);

        window.location.assign('progetti');

      } else {
        console.log('error code:', status);
        if (status === 401) {setAreCredentialsCorrect(false)}
      }
    });
  };

  const clickHandler = () => setShowPass(!showPass);


  if(process.env.REACT_APP_DEMO_USER  && process.env.REACT_APP_DEMO_PASSWORD) {
    handleLogin(new Event('Foo'))
  }


  return (
    <>
      <div className="login-container mt-3">
        <Container>
          <Row className="justify-content-center text-center">
            <Col lg={5} className="admin-login form-login">
              <h5 className="title-login-admin">Accedi</h5>
              <Form id="ics-access-form">
                <Form.Group className="mb-4">                            
                  <Form.Control type="email" name="email" value={email} placeholder="Email" onChange={({target: { value }}) => setEmail(value)} />
                </Form.Group>
                <Form.Group className="mb-4">  
                  <InputGroup>                         
                    <Form.Control 
                      type={showPass ? "text" : "password"}
                      rows={3} 
                      name="password" 
                      value={password} 
                      placeholder="Password" 
                      onChange={({target: { value }}) => setPassword(value)} 
                    />
                    <InputGroup.Text className="addon-for-number">
                      {showPass ? <Eye className="eye" onClick={clickHandler} /> : <EyeSlash className="eye" onClick={clickHandler} />}
                    </InputGroup.Text>
                  </InputGroup> 

                  {areCredentialsCorrect ? null : <span className="errorMessage"><h5>Credenziali errate.</h5></span>}
                </Form.Group>                          
              </Form>

              <Button
                variant="info"
                form="ics-access-form"
                style={{ backgroundColor: '#1C2743', borderColor: '#1C2743' }}
                type="submit"
                onClick={handleLogin}
              >
                Login
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AdminAccess;
