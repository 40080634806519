import React, { useState } from 'react';
import { Video } from '@icons';

const variants = {
  base: {
    className: 'tutorial-button',
    text: 'Hai bisogno di aiuto? Guarda il video tutorial di questa sezione',
    icon: <Video />,
  },
  thanks: {
    className: 'tutorial-button-thanks',
    text: 'Guarda il video tutorial per finalizzare progetto',
    icon: <Video />,
  },
  header: {
    className: 'tutorial-button-header',
    text: 'Guarda i video tutorial per la compilazione',
    icon: <Video />,
  },
};

const TutorialsButtons = ({ url, variant = 'base', setState }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleClick = () => setState(true);

  const getButtonProps = () => {
    const baseProps = {
      className: `tutorial-button d-flex justify-content-center align-items-center ${
        isHovered && variant !== 'thanks'
          ? 'hovered'
          : isHovered && variant === 'thanks'
          ? 'hovered-small'
          : ''
      } ${variants[variant].className}`,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    };

    return variant === 'header'
      ? { ...baseProps, href: url, target: '_blank', rel: 'noreferrer' }
      : { ...baseProps, onClick: handleClick };
  };

  const ButtonComponent = variant === 'header' ? 'a' : 'button';

  return (
    <div></div>
  );
};

export default TutorialsButtons;
