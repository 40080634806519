import React from 'react';
import { /* Link, */ withRouter, useLocation } from 'react-router-dom';
import { NavDropdown, Nav, Navbar, Dropdown /* Figure */ } from 'react-bootstrap';
import { User, Logout, UsersGear } from '@icons';
import {
  Externalyticslogo,
  PoweredbyOE,
  CreditoSportivoLogo,
  /*FondazioneElis, FifaLogo, OpeneconomicsLogo ,*/
} from 'loghi';
import TutorialsButtons from './TutorialsButtons';
//import { geoClipRectangle } from 'd3';

/* import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Trans } from '@lib/i18n';
import Currency from '@lib/currency'; */

/* const langoptions = [
  { value: 'it', label: 'ITA' },
  { value: 'es', label: 'ESP' },
  { value: 'en', label: 'ENG' },
]; */

/* const customStyles = {
  option: (provided) => ({
    ...provided,
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    padding: '2px 8px 2px 0px',
  }),
  input: () => ({
    margin: '0px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, color: '#666666' };
  },
  valueContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    width: '100%',
  }),
}; */

/* const LanguageSelect = () => {
  const { i18n } = useTranslation();

  const handleChange = ({ value }) => {
    i18n.changeLanguage(value);
    localStorage.setItem('externalyticsLanguage', value);
    window.location.reload();
  };

  return (
    <Select
      styles={customStyles}
      defaultValue={langoptions.find((l) => l.value === i18n.language)}
      options={langoptions}
      onChange={handleChange}
      classNamePrefix="custom-select"
    />
  );
}; */

/* class Langselect extends React.Component {
  render() {
    return (
      <Select
        styles={customStyles}
        defaultValue={langoptions[0]}
        options={langoptions}
        classNamePrefix="custom-select"
      />
    );
  }
} */

/* class Currselect extends React.Component {
  render() {
    return (
      <Select
        styles={customStyles}
        defaultValue={Currency.getCurrencyObject()}
        options={Currency.currencies}
        classNamePrefix="custom-select"
        onChange={Currency.setCurrencyObject}
        isDisabled={true}
      />
    );
  }
} */

const Header = () => {
  const location = useLocation();
  const userEmail = localStorage.getItem('ics_email');
  const isSurvey = location.pathname.indexOf('survey') === 1 ? true : false;
  const isLoginAdmin = location.pathname.indexOf('admin') === 1 ? true : false;
  const isClientThanks = location.pathname.indexOf('thanks') === 1 ? true : false;
  const isIcsClient = Number(localStorage.getItem('ics_role')) === 1 ? true : false;
  //const isICS = Number(localStorage.getItem('ics_role')) === 2 ? true : false;
  const isSuperAdmin = Number(localStorage.getItem('ics_role')) === 3 ? true : false;

  /* const setLang = (lang) => {
     localStorage.setItem("userLanguage",lang)
     window.location.reload();
   } */

  /* const getLang = () => {
     return localStorage.getItem("userLanguage") ?? 'IT'
   }
  */
  return (
    <>
      {isClientThanks ? (
        <></>
      ) : (
        <header id='site-header'>
          <Navbar
            expand='lg'
            variant='light'
            style={isSurvey || isLoginAdmin ? { display: 'block' } : null}>
            {process.env.REACT_APP_DEMO_USER && process.env.REACT_APP_DEMO_PASSWORD ? (
              <Nav
                className='mr-auto left-side text-center'
                style={isSurvey || isLoginAdmin ? { justifyContent: 'space-between' } : null}>
                <Navbar.Brand /* href="/" */>
                  <Externalyticslogo className='icslogo' />
                  {/* <FondazioneElis className="icslogo" /> */}
                  {/* <FifaLogo className="icslogo" /> */}

                  {/*  <img style={{maxHeight:'3rem'}}
                              alt="Logo Generali"
                              src="/img/generali-logo-orizzontale-oe.jpg"
                           /> 

                           <img style={{maxHeight:'4rem'}}
                              alt="Logo Generali"
                              src="/img/meeting-di-rimini-logo.png"
                           />*/}
                </Navbar.Brand>
                <div className='powered-by-header'>
                  {/* <small>Powered by</small>
                           <OpeneconomicsLogo className="externalyticslogo" /> */}
                  <PoweredbyOE />
                </div>
              </Nav>
            ) : (
              <Nav
                className='mr-auto left-side text-center'
                style={isSurvey || isLoginAdmin ? { justifyContent: 'space-between' } : null}>
                <Navbar.Brand /* href="/" */>
                  {/* <CreditoSportivoLogo style={{ maxWidth: '270px' }} /> */}
                </Navbar.Brand>
                {/* <div className='powered-by-header'>
                  <small>Powered by</small>
                  <Externalyticslogo className='externalyticslogo' />
                </div> */}
              </Nav>
            )}

            <Nav className='ml-auto justify-content-end' style={{ flexFlow: 'nowrap' }}>
              {/* <Nav.Link href='https://youtu.be/DnYvK0SpFzk' target='_blank' className='links-nav'>
                GOOGLE
              </Nav.Link> */}
              <TutorialsButtons
                url='https://youtube.com/playlist?list=PLwg9RWqleYIt8OXAlrEIAbR9NCrQnMvat&si=3KwE18JJ6vgvlLlA'
                variant='header'
              />
              {/* <Nav.Link href='/faq' className='links-nav'>
                FAQ
              </Nav.Link> */}
              {isSurvey || isLoginAdmin || isIcsClient ? (
                <></>
              ) : (
                <>
                  {/* <NavDropdown
                              id="user-nav-dropdown"
                              title={getLang()}
                              className="user user-nav-dropdown"
                           >
                              <NavDropdown.Item onClick={() => setLang("IT")} className="text-right">
                                 IT
                              </NavDropdown.Item> 
                              <NavDropdown.Item onClick={() => setLang("EN")} className="text-right">
                                 EN
                              </NavDropdown.Item>
                           </NavDropdown>*/}

                  <NavDropdown
                    id='user-nav-dropdown'
                    title={<User className='userico' />}
                    className='user user-nav-dropdown mr-1 mb-1 ml-3'>
                    {userEmail ? (
                      <>
                        <Dropdown.Header className='text-right' style={{ padding: '0.5rem 0' }}>
                          {userEmail}
                        </Dropdown.Header>
                        <Dropdown.Divider />
                      </>
                    ) : null}
                    {isSuperAdmin ? (
                      <NavDropdown.Item href='/gestione-utenti' className='text-right'>
                        Gestione utenti&nbsp;
                        <UsersGear className='dropdown-icon' />
                      </NavDropdown.Item>
                    ) : null}
                    <NavDropdown.Item href='/logout' className='text-right'>
                      Logout&nbsp;
                      <Logout className='dropdown-icon' />
                    </NavDropdown.Item>
                  </NavDropdown>

                  {/* <Nav.Link href="#home" className="d-none d-lg-block">ABOUT</Nav.Link> */}
                  {/* <NavDropdown
                              id="about-nav-dropdown"
                              title="ABOUT"
                              className="about d-none d-lg-block"
                              >
                              <NavDropdown.Item>
                                 <Link to="/about">About</Link>
                              </NavDropdown.Item>
                              </NavDropdown> */}
                  {/* <Form inline className="d-none d-lg-block select-lang"> 
                              <LanguageSelect/>
                              </Form>
                              <Form inline className="d-none d-lg-block select-currency"> 
                              <Currselect/>
                              </Form> 
                           */}
                </>
              )}
            </Nav>
          </Navbar>

          {/* <Navbar className="poweredby">
                     <div>
                        <small>Powered by</small>
                        <Externalyticslogo className="externalyticslogo" />
                     </div>
                  </Navbar> 
               */}

          {isIcsClient || isSurvey || isLoginAdmin || isClientThanks ? null : (
            <Navbar variant='light' className='sub-navbar'>
              <Nav activeKey={location.pathname.split('/').slice(0, 2).join('/')}>
                <Nav.Item>
                  <Nav.Link href='/progetti'>Progetti</Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar>
          )}
        </header>
      )}
    </>
  );
};

export default withRouter(Header);
