import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { OpeneconomicsLogo } from 'loghi';
import moment from 'moment';

function Footer() {
  return (
    <footer id='site-footer'>
      <Container>
        <Row>
          
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
